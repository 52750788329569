<template>
    <div class="modal" v-if="modelValue" :class="{ 'active' : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Workouts Details</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="details" v-if="type == 'completed'">
                    <h4 class="sub_heading2 mb-3">Week 1 Foundation - Legs & Core</h4>
                    <div class="blocks">
                        <div class="block_title">
                            <div>Warm-up<h6><span>1 round</span><span>1 min work</span><span>20 sec rest</span></h6></div>
                            <span class="tag">Performed as a Circuit</span>
                        </div>
                        <div class="block_item border-0">
                            <div class="details_info">
                                <h5>Squat</h5>
                                <table class="task_history">
                                    <thead>
                                        <tr>
                                            <th>Work Time</th>
                                            <th>Load</th>
                                            <th>Rest Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <label>01 : 00</label>
                                                <div class="reset">Min<input type="number" value="1">Sec<input type="number" value="0"></div>
                                            </td>
                                            <td>
                                                <label>Bodyweight</label>
                                                <div class="reset"><input type="text" value="Bodyweight"></div>
                                            </td>
                                            <td>
                                                <label>00 : 20</label>
                                                <div class="reset">Min<input type="number" value="0">Sec<input type="number" value="20"></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="block_item border-0">
                            <div class="details_info">
                                <h5>Resistance Band Lateral Walk</h5>
                                <table class="task_history">
                                    <thead>
                                        <tr>
                                            <th>Work Time</th>
                                            <th>Load</th>
                                            <th>Rest Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <label>01 : 00</label>
                                                <div class="reset">Min<input type="number" value="1">Sec<input type="number" value="0"></div>
                                            </td>
                                            <td>
                                                <label>Bodyweight</label>
                                                <div class="reset"><input type="text" value="Bodyweight"></div>
                                            </td>
                                            <td>
                                                <label>00 : 20</label>
                                                <div class="reset">Min<input type="number" value="0">Sec<input type="number" value="20"></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="block_item border-0">
                            <div class="details_info">
                                <h5>Medicine Ball Lunge with Biceps Curl</h5>
                                <table class="task_history">
                                    <thead>
                                        <tr>
                                            <th>Work Time</th>
                                            <th>Load</th>
                                            <th>Rest Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <tr>
                                            <td>
                                                <label>01 : 00</label>
                                                <div class="reset">Min<input type="number" value="1">Sec<input type="number" value="0"></div>
                                            </td>
                                            <td>
                                                <label>10lbs</label>
                                                <div class="reset"><input type="number" value="10">lbs</div>
                                            </td>
                                            <td>
                                                <label>00 : 20</label>
                                                <div class="reset">Min<input type="number" value="0">Sec<input type="number" value="20"></div>
                                            </td>
                                        </tr>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <a class="redirect_btn success" @click="handleBlockEdit($event)">Edit<i class="fas fa-long-arrow-alt-right"></i></a>
                        <a class="save_btn" @click="handleBlockEdit($event)">Save</a>
                    </div>
                    <div class="blocks">
                        <div class="block_title"><div>Legs and Core</div><span class="tag">Performed as a Progression</span></div>
                        <div class="block_item border-0">
                            <div class="details_info">
                                <h5>Kettlebell Goblet Squat</h5>
                                <table class="task_history">
                                    <thead>
                                        <tr>
                                            <th>Set</th>
                                            <th>Reps</th>
                                            <th>Tempo</th>
                                            <th>Load</th>
                                            <th>Rest Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="set">3</td>
                                            <td>
                                                <label>12</label>
                                                <div class="reset"><input type="number" value="12"></div>
                                            </td>
                                            <td>
                                                <label>3131</label>
                                                <div class="reset"><input type="text" value="3131"></div>
                                            </td>
                                            <td>
                                                <label>60% 1rm</label>
                                                <div class="reset"><input type="number" value="60">% 1rm</div>
                                            </td>
                                            <td>
                                                <label>00 : 30</label>
                                                <div class="reset">M<input type="number" value="0">S<input type="number" value="30"></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="block_item border-0">
                            <div class="details_info">
                                <h5>Dumbbell Split Squat</h5>
                                <table class="task_history">
                                    <thead>
                                        <tr>
                                            <th>Set</th>
                                            <th>Reps</th>
                                            <th>Tempo</th>
                                            <th>Load</th>
                                            <th>Rest Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="set">3</td>
                                            <td>
                                                <label>12</label>
                                                <div class="reset"><input type="number" value="12"></div>
                                            </td>
                                            <td>
                                                <label>3131</label>
                                                <div class="reset"><input type="text" value="3131"></div>
                                            </td>
                                            <td>
                                                <label>60% 1rm</label>
                                                <div class="reset"><input type="number" value="60">% 1rm</div>
                                            </td>
                                            <td>
                                                <label>00 : 30</label>
                                                <div class="reset">M<input type="number" value="0">S<input type="number" value="30"></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="block_item border-0">
                            <div class="details_info">
                                <h5>Step up</h5>
                                <table class="task_history">
                                    <thead>
                                        <tr>
                                            <th>Set</th>
                                            <th>Reps</th>
                                            <th>Tempo</th>
                                            <th>Load</th>
                                            <th>Rest Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="set">3</td>
                                            <td>
                                                <label>12</label>
                                                <div class="reset"><input type="number" value="12"></div>
                                            </td>
                                            <td>
                                                <label>3131</label>
                                                <div class="reset"><input type="text" value="3131"></div>
                                            </td>
                                            <td>
                                                <label>Bodyweight</label>
                                                <div class="reset"><input type="text" value="Bodyweight"></div>
                                            </td>
                                            <td>
                                                <label>00 : 30</label>
                                                <div class="reset">M<input type="number" value="0">S<input type="number" value="30"></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="block_item border-0">
                            <div class="details_info">
                                <h5>Twisting Crunch (Straight Arms)</h5>
                                <table class="task_history">
                                    <thead>
                                        <tr>
                                            <th>Set</th>
                                            <th>Reps</th>
                                            <th>Tempo</th>
                                            <th>Load</th>
                                            <th>Rest Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="set">3</td>
                                            <td>
                                                <label>12</label>
                                                <div class="reset"><input type="number" value="12"></div>
                                            </td>
                                            <td>
                                                <label>3131</label>
                                                <div class="reset"><input type="text" value="3131"></div>
                                            </td>
                                            <td>
                                                <label>Bodyweight</label>
                                                <div class="reset"><input type="text" value="Bodyweight"></div>
                                            </td>
                                            <td>
                                                <label>00 : 30</label>
                                                <div class="reset">M<input type="number" value="0">S<input type="number" value="30"></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="block_item border-0">
                            <div class="details_info">
                                <h5>Spell Caster</h5>
                                <table class="task_history">
                                    <thead>
                                        <tr>
                                            <th>Set</th>
                                            <th>Reps</th>
                                            <th>Tempo</th>
                                            <th>Load</th>
                                            <th>Rest Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="set">3</td>
                                            <td>
                                                <label>12</label>
                                                <div class="reset"><input type="number" value="12"></div>
                                            </td>
                                            <td>
                                                <label>3131</label>
                                                <div class="reset"><input type="text" value="3131"></div>
                                            </td>
                                            <td>
                                                <label>60% 1rm</label>
                                                <div class="reset"><input type="number" value="60">% 1rm</div>
                                            </td>
                                            <td>
                                                <label>00 : 30</label>
                                                <div class="reset">M<input type="number" value="0">S<input type="number" value="30"></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <a class="redirect_btn success" @click="handleBlockEdit($event)">Edit<i class="fas fa-long-arrow-alt-right"></i></a>
                        <a class="save_btn" @click="handleBlockEdit($event)">Save</a>
                    </div>
                </div>
                <div class="details" v-if="type == 'incomplete'">
                    <h4 class="sub_heading2 mb-3">Week 1 Foundation - Legs & Core</h4>
                    <div class="blocks">
                        <div class="block_title">
                            <div>Warm-up<h6><span>1 round</span><span>1 min work</span><span>20 sec rest</span></h6></div>
                            <span class="tag">Performed as a Circuit</span>
                        </div>
                        <div class="block_item">
                            <div class="video_area">
                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Glutes/Gluteus-Maximus/Squat.gif" at="Squat">
                            </div>
                            <div class="details_info">
                                <h5>Squat</h5>
                                <h6>
                                    <span>1 min</span>
                                    <span>Bodyweight</span>
                                    <span>20 sec rest</span>
                                </h6>
                            </div>
                        </div>
                        <div class="block_item">
                            <div class="video_area">
                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Glutes/Gluteus-Medius/Resistance-Band-Lateral-Walk.gif" at="Resistance Band Lateral Walk">
                            </div>
                            <div class="details_info">
                                <h5>Resistance Band Lateral Walk</h5>
                                <h6>
                                    <span>1 min</span>
                                    <span>Bodyweight</span>
                                    <span>20 sec rest</span>
                                </h6>
                            </div>
                        </div>
                        <div class="block_item">
                            <div class="video_area">
                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Quadriceps-Group/Quadriceps/Medicine-Ball-Lunge-with-Biceps-Curl.gif" at="Medicine Ball Lunge with Biceps Curl">
                            </div>
                            <div class="details_info">
                                <h5>Medicine Ball Lunge with Biceps Curl</h5>
                                <h6>
                                    <span>1 min</span>
                                    <span>10 lbs</span>
                                    <span>20 sec rest</span>
                                </h6>
                            </div>
                        </div>
                        <a class="redirect_btn" href="https://reachvine.com/STQx63r9" target="_blank">Complete now<i class="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                    <div class="blocks">
                        <div class="block_title"><div>Legs and Core</div><span class="tag">Perform as a Progression</span></div>
                        <div class="block_item">
                            <div class="video_area">
                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Glutes/Gluteus-Maximus/Kettlebell-Goblet-Squat.gif" at="Kettlebell Goblet Squat">
                            </div>
                            <div class="details_info">
                                <h5>Kettlebell Goblet Squat</h5>
                                <h6>
                                    <span>3 sets</span>
                                    <span>12 reps</span>
                                    <span>60% of 1rm</span>
                                </h6>
                            </div>
                        </div>
                        <div class="block_item">
                            <div class="video_area">
                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Quadriceps-Group/Quadriceps/Dumbbell-Split-Squat.gif" at="Dumbbell Split Squat">
                            </div>
                            <div class="details_info">
                                <h5>Dumbbell Split Squat</h5>
                                <h6>
                                    <span>3 sets</span>
                                    <span>12 reps</span>
                                    <span>60% of 1rm</span>
                                </h6>
                            </div>
                        </div>
                        <div class="block_item">
                            <div class="video_area">
                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Quadriceps-Group/Quadriceps/Step-up.gif" at="Step up">
                            </div>
                            <div class="details_info">
                                <h5>Step up</h5>
                                <h6>
                                    <span>3 sets</span>
                                    <span>12 reps</span>
                                    <span>Bodyweight</span>
                                </h6>
                            </div>
                        </div>
                        <div class="block_item">
                            <div class="video_area">
                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Abs---Core/Rectus-Abdominus---Completed/Twisting-Crunch--Straight-Arms-.gif" at="Twisting Crunch (Straight Arms)">
                            </div>
                            <div class="details_info">
                                <h5>Twisting Crunch (Straight Arms)</h5>
                                <h6>
                                    <span>3 sets</span>
                                    <span>12 reps</span>
                                    <span>Bodyweight</span>
                                </h6>
                            </div>
                        </div>
                        <div class="block_item">
                            <div class="video_area">
                                <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Abs---Core/Rectus-Abdominus---Completed/Spell-Caster.gif" at="Spell Caster">
                            </div>
                            <div class="details_info">
                                <h5>Spell Caster</h5>
                                <h6>
                                    <span>3 sets</span>
                                    <span>12 reps</span>
                                    <span>60% of 1rm</span>
                                </h6>
                            </div>
                        </div>
                        <a class="redirect_btn" href="https://reachvine.com/STQx63r9" target="_blank">Complete now <i class="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Task Details',

    data(){
        return{

        }
    },

    props: {
        modelValue: Boolean,
        type: String,
    },
    emits: ['update:modelValue'],

    methods:{
        closeModal () {
            const vm = this;
            vm.$emit('update:modelValue', false);
        },

        handleBlockEdit(ev){
            const vm = this;
            let elm = ev.currentTarget.closest('.blocks');
            
            if(elm.classList.contains('edit')){
                elm.classList.remove('edit');
            } else{
                elm.classList.add('edit');
            }
        }
    }
}
</script>

<style scoped>
.details {
    display: flex;
    flex-wrap: wrap;
    transition: all 0.3s ease-in-out;
}
.details .rest_info{
    font-size: 11px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
}
.details .redirect_btn{
    font-size: 11px;
    line-height: 14px;
    padding: 6px 12px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    margin: 0 0 0 auto;
    text-decoration: none;
    color: #ff5c5c;
}
.details .redirect_btn.success{
    color: #00aa14;
}
.save_btn{
    font-size: 13px;
    line-height: 16px;
    padding: 7px 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 0 0 auto;
    text-decoration: none;
    background: #00aa14;
    color: #fff;
}
.details .blocks{
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 6px;
    padding: 20px;
    flex: 1 1 auto;
    margin-bottom: 20px;
}
.details .blocks .block_title{
    font-size: 15px;
    line-height: 20px;
    color: #121525;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.details .blocks .block_title .tag{
    background: #f5f5f5;
    padding: 3px 10px;
    border-radius: 10px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
    margin-left: auto;
}
.details .blocks .block_title h6{
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #5a5a5a;
    font-weight: 500;
    margin-top: 8px;
}
.details .blocks .block_title h6 span{
    padding: 0 5px 0 0;
    margin-right: 5px;
}
.details .blocks .block_title h6 span:not(:last-child){
    border-right: 1px solid #d9d9d9;
}
.details .blocks.edit{
    margin-left: 0;
}
.details .blocks.edit input[type=number]{
    width: 35px;
    padding: 0 3px;
    text-align: center;
    font-size: 11px;
}
.details .blocks.edit input[type=text]{
    width: 80px;
    padding: 0 3px;
    text-align: center;
    font-size: 11px;
}
.details .blocks .reset,
.details .blocks .save_btn,
.details .blocks.edit label,
.details .blocks.edit .redirect_btn{
    display: none;
}
.details .blocks.edit .reset,
.details .blocks.edit .save_btn{
    display: flex;
    justify-content: center;
    align-items: center; 
}
.details .block_item{
    border: 1px solid #f5f5f5;
    background: #fff;
    border-radius: 5px;
    border-radius: 10px;
    display: flex;
    margin-bottom: 20px;
}
.details .block_item .date{
    font-size: 11px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 400;
}
.details .block_item h5{
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
}
.details .block_item h6{
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 400;
}
.details .block_item h6 .v_tag {
    width: 14px;
    height: 14px;
    background: #999;
    border: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    line-height: 10px;
    font-weight: 500;
    color: #fff;
    margin-right: 5px;
    border-radius: 50%;
    padding: 0;
}
.details .block_item h6 span{
    padding: 0 5px 0 0;
    margin-right: 5px;
}
.details .block_item h6 span:not(:last-child){
    border-right: 1px solid #d9d9d9;
}
table.task_history{
    width: 100%;
    border-spacing: 8px;
    background: #f5f5f5;
    border-radius: 5px;
    padding: 0 5px 5px 5px;
}
.task_history th, .task_history td {
    padding: 10px 0;
    border-radius: 3px;
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
}
.task_history td {
    width: 23%;
    padding: 10px 3px;
    background: #fff;
    color: #777;
}
.task_history td.set{
    width: 80px;
    background: #fbfbfb;
}
.video_area{
    width: 25%;
    border-right: 1px solid #f5f5f5;
    margin-right: 15px;
}
.video_area img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.details .details_info{
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
}
</style>